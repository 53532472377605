:root
{
  --yellow: #F5C32C;
    --orange : #FCA61F;
    --black : #242D49;
    --gray : #788097;
    --blueCard : #DDF8FE;
    --purple: rgb(238 210 255);
    --boxShadow : 0px 19px 60px rgb(0 0 0 / 8%);
    --orangeCard: rgba(252, 166, 31, 0.45);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}
*{
  font-family: Gumela;
  font-family:Gumela;         
        font-weight: bold;
        font-size: 20px;
}
@font-face{
        font-family: Gumela;
        src: url('./fonts/Gumela\ Regular.otf');
      }
.App{
    padding: 0.5rem 3.5rem;
    overflow: hidden;
    color: var(--black);    
}
.button{
  border-radius: 34px;
  border: none;
  color: white;
  font-size: 15px;
  padding: 11px 26px 11px 26px;
  background:linear-gradient(180deg,#fdc506 25%,#fb982f 99%); 
  box-shadow: 0 20px 24px 3px rgba(251, 161, 40, 0.46)
}
.button:hover{
  cursor: pointer;
  background: white;
  color: var(--orange);
  border: 1px solid var(--orange);

}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: orange; 
   border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
@media  screen and (max-width:480px) {
  .App{
    padding: 0.5rem 1rem;
  }
}


  

  